import React from "react";
import './portfolio.css';
import IMG1 from '../../assets/1.png';
import IMG2 from '../../assets/3.png';
import IMG3 from '../../assets/mainpic.png';
import IMG4 from '../../assets/mainpic2.png';
import IMG5 from '../../assets/mainpic3.png';
import IMG6 from '../../assets/22.png';
const data=[
    {
        id:1,
        image:IMG1,
        title:'subject',
        github:'https://github.com/mahdikargar-cmd',
        demo:'https://dribbble.com/ghost2002'
    },
    {
        id:2,
        image:IMG2,
        title:'subject',
        github:'https://github.com/mahdikargar-cmd',
        demo:'https://dribbble.com/ghost2002'
    },    {
        id:3,
        image:IMG3,
        title:'subject',
        github:'https://github.com/mahdikargar-cmd',
        demo:'https://dribbble.com/ghost2002'
    },    {
        id:4,
        image:IMG4,
        title:'subject',
        github:'https://github.com/mahdikargar-cmd',
        demo:'https://dribbble.com/ghost2002'
    },    {
        id:5,
        image:IMG5,
        title:'subject',
        github:'https://github.com/mahdikargar-cmd',
        demo:'https://dribbble.com/ghost2002'
    },    {
        id:6,
        image:IMG6,
        title:'subject',
        github:'https://github.com/mahdikargar-cmd',
        demo:'https://dribbble.com/ghost2002'
    },


]

const Portfolio = () => {
    return (
        <section id="portfolio">
            <h5>My Recent Work</h5>
            <h2>Portfolio</h2>
            <div className="container portfolio__container">
                <article className="portfolio__item">
                    <div className="portfolio__item-image">
                        <img src={IMG1} alt=""/>
                    </div>
                    <h3>Graph-Coloring-With-React</h3>
                    <div className="portfolio__item-cta">
                        <a href="https://github.com/mahdikargar-cmd/Graph-Coloring-With-React" className="btn" target='_blank'>Github</a>
                    </div>

                </article>
                <article className="portfolio__item">
                    <div className="portfolio__item-image">
                        <img src={IMG2} alt=""/>
                    </div>
                    <h3>an ecommerce website with MERN</h3>
                    <div className="portfolio__item-cta">
                        <a href="https://github.com/mahdikargar-cmd/ecommerce" className="btn" target='_blank'>Github</a>
                    </div>
                </article>
                <article className="portfolio__item">
                    <div className="portfolio__item-image">
                        <img src={IMG3} alt=""/>
                    </div>
                    <h3>clothing-shop-ui</h3>
                    <div className="portfolio__item-cta">
                        <a href="https://github.com/mahdikargar-cmd/clothing-shop-ui" className="btn" target='_blank'>Github</a>
                    </div>
                </article>
                <article className="portfolio__item">
                    <div className="portfolio__item-image">
                        <img src={IMG4} alt=""/>
                    </div>
                    <h3>car-gallery-ui</h3>
                    <div className="portfolio__item-cta">
                        <a href="https://github.com/mahdikargar-cmd/car-gallery-ui" className="btn" target='_blank'>Github</a>
                    </div>
                </article>
                <article className="portfolio__item">
                    <div className="portfolio__item-image">
                        <img src={IMG5} alt=""/>
                    </div>
                    <h3>ui-corporate-website</h3>
                    <div className="portfolio__item-cta">
                        <a href="https://github.com/mahdikargar-cmd/ui-corporate-website" className="btn" target='_blank'>Github</a>
                    </div>
                </article>
                <article className="portfolio__item">
                    <div className="portfolio__item-image">
                        <img src={IMG6} alt=""/>
                    </div>
                    <h3>register-login-form</h3>
                    <div className="portfolio__item-cta">
                        <a href="https://github.com/mahdikargar-cmd/register-login-form" className="btn" target='_blank'>Github</a>
                    </div>
                </article>

            </div>

        </section>
    )
}
export default Portfolio