import React from "react";
import {useState} from "react";
import './nav.css';
import {AiOutlineHome} from 'react-icons/ai';
import {AiOutlineUser} from 'react-icons/ai';
import {BsJournalBookmark} from 'react-icons/bs';
import {RiServiceLine} from 'react-icons/ri';
import {FaHome} from 'react-icons/fa';

const Nav = () => {
    const [activeNav, setActiveNav] = useState('#');
    return (
        <nav>
            <a href="#" onClick={() => setActiveNav('#')} className={activeNav === '#' ? 'active' : ''}><AiOutlineHome/></a>
            <a href="#about"  onClick={() => setActiveNav('#about')}  className={activeNav === '#about' ? 'active' : ''}><AiOutlineUser/></a>
            <a href="#exprience" onClick={() => setActiveNav('#exprience')} className={activeNav === '#exprience' ? 'active' : ''}><BsJournalBookmark/></a>
            <a href="#services" onClick={() => setActiveNav('#services')} className={activeNav === '#services' ? 'active' : ''}><RiServiceLine/></a>
            <a href="#contact" onClick={() => setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''}><FaHome/></a>

        </nav>
    );
};
export default Nav