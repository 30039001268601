import React from "react";
import './services.css';
import {BiCheck} from "react-icons/bi";

const Services = () => {
    return (
        <section id="services">
            <h5>What I Offer</h5>
            <h2>Services</h2>
            <div className="services__container container">
                <article className="service">
                    <div className="service__head">
                        <h3>UI Design</h3>
                    </div>
                    <ul className="service__list">
                        <li>
                            <BiCheck className="service__list-icon"/>
                            <p>UI Design, as both an art and science, plays a vital role in enhancing user experience
                                and engagement with websites and software. <br/>
                                also In today's digital world, UI Design holds a crucial place in visual communication
                                with
                                users; through crafting visually appealing and efficient user interfaces, we elevate the
                                user experience to new levels of satisfaction and ease.
                            </p>
                        </li>

                    </ul>
                </article>
                <article className="service">
                    <div className="service__head">
                        <h3>Dedicated Website coding</h3>
                    </div>
                    <ul className="service__list">
                        <li>
                            <BiCheck className="service__list-icon"/>
                            <p>Infused with fervor and enthusiasm, I craft a realm like no other in the language of
                                code, unlike anything seen before.</p>
                        </li>
                        <li>
                            <BiCheck className="service__list-icon"/>
                            <p>Coding isn't just my skill; it's my art, where lines of logic weave into a masterpiece of
                                innovation and functionality.</p>
                        </li>
                        <li>
                            <BiCheck className="service__list-icon"/>
                            <p>Every keystroke is a brushstroke, painting a digital canvas of limitless possibilities,
                                fueled by creativity and precision.</p>
                        </li>

                    </ul>
                </article>
                <article className="service">
                    <div className="service__head">
                        <h3>Development of dynamic web applications</h3>
                    </div>
                    <ul className="service__list">
                        <li>
                            <BiCheck className="service__list-icon"/>
                            <p>Utilizing advanced technologies like Node.js and React, we continuously develop dynamic
                                and resilient web applications that attract and retain audiences.<br/>
                                Dynamic web development not only reflects the essence of your business but also provides
                                the flexibility to meet diverse needs and rapid changes.<br/>
                                With Node.js and React, we design web applications that effortlessly adapt to users'
                                daily needs and market fluctuations, resulting in an exceptional user experience.
                            </p>
                        </li>
                    </ul>
                </article>


            </div>

        </section>
    )
}
export default Services