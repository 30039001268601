import React from "react";
import {BsLinkedin} from 'react-icons/bs';
import {FaGithub} from 'react-icons/fa';
import {FaInstagram} from 'react-icons/fa';
import './header.css'


const HeaderSocials = () => {
    return (
        <div className="header__socials">
            <a href="https://www.linkedin.com/in/mahdi-kargar-949760190?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
               target="_blank"><BsLinkedin/></a>
            <a href="https://github.com/mahdikargar-cmd" target="_blank"><FaGithub/></a>
            <a href="https://www.instagram.com/mahdi._kargarr?igsh=cHpmd29mYjdlNmtt" target="_blank"><FaInstagram/></a>
        </div>


    )
}
export default HeaderSocials