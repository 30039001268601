import React from "react";
import CV from'../../assets/resome.pdf'
import './header.css'

const CTA = () => {
    return (
     <div className="cta">
         <a href={CV} download="" className="btn">Download CV</a>
         <a href="#contact" className="btn btn-primary">Lets a Talk</a>
     </div>
    )
}
export default CTA