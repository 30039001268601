import React from "react";
import './exprience.css'
import {BsPatchCheckFill} from "react-icons/bs";

const Exprience = () => {
    const skills = [
        {
            skill: "Html",
            level: "Exprienced"
        },
        {
            skill: "Css",
            level: "Exprienced"
        }, {
            skill: "JavaScript",
            level: "Exprienced"
        }, {
            skill: "React.js",
            level: "Exprienced"
        }, {
            skill: "Restfull Api",
            level: "Exprienced"
        }, {
            skill: "Axios",
            level: "Exprienced"
        }, {
            skill: "Tailwind framework",
            level: "Exprienced"
        }, {
            skill: "Bootstrap Framework",
            level: "Exprienced"
        },
    ]
    return (
        <section id="exprience">
            <h5>What Skills I Have</h5>
            <h2>My Exprience</h2>

            <div className="container exprience__container">
                <div className="">
                    <h3>front end Developer</h3>
                    <div className="exprience__content ">
                        {
                            skills.map((skill) => (
                                <div>
                                    <article className="exprience__details">
                                        <BsPatchCheckFill className="exprience__details-icon"/>
                                        <div>
                                            <h4>{skill.skill} </h4>
                                            <small className="text-light">{skill.level}</small>
                                        </div>
                                    </article>

                                </div>
                            ))
                        }

                    </div>

                </div>

            </div>


        </section>)
}
export default Exprience