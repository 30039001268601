import React, { useRef } from "react";
import './contact.css'
import { MdOutlineEmail } from "react-icons/md";
import { RiMessengerLine } from "react-icons/ri";
import { BsWhatsapp } from "react-icons/bs";
import emailjs from 'emailjs-com';

const Contact = () => { // Capitalized the component name
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_7kmk3qe', 'template_awqp4g3', form.current, 'etsjriY8bvSppTPwC');
        e.target.reset();
    }

    return (
        <section id="contact">
            <h5>Get In Touch</h5>
            <h2>Contact Me</h2>

            <div className="container contact__container">
                <div className="contact__options">
                    <article className="contact__option">
                        <MdOutlineEmail className="contact__option-icon"/>
                        <h4>Email</h4>
                        <h5>mahdikargar.com@gmail.com</h5>
                        <a href="mailto:mahdikargar.com@gmail.com" target="_blank" rel="noopener noreferrer">Send a message</a>
                    </article>
                    <article className="contact__option">
                        <RiMessengerLine className="contact__option-icon"/>
                        <h4>Messenger</h4>
                        <h5>egator</h5>
                        <a href="https://m.me.com" target="_blank" rel="noopener noreferrer">Send a message</a>
                    </article>
                    <article className="contact__option">
                        <BsWhatsapp className="contact__option-icon"/>
                        <h4>WhatsApp</h4>
                        <h5>+98-992 661 8623</h5>
                        <a href="https://whatsapp.com" target="_blank" rel="noopener noreferrer">Send a message</a>
                    </article>
                </div>
                <form ref={form} onSubmit={sendEmail}>
                    <input type="text" name='name' placeholder='Your full Name' required/>
                    <input type="email" name='email' placeholder='Your Email' required/>
                    <textarea name="message" placeholder="Your Message" rows="7" required></textarea>
                    <button type='submit' className="btn btn-primary">Send Message</button>
                </form>
            </div>
        </section>
    )
}

export default Contact; // Exporting with capitalized name
