import React from "react";
import Header from "./component/Header/Header";
import Nav from "./component/Nav/nav";
import About from "./component/about/About";
import Exprience from "./component/exprience/Exprience";
import Services from "./component/services/services";
import Contact from "./component/contact/contact";
import Portfolio from "./component/Portfolio/portfolio";

const App = () => {
    return (
        <>

            <Header/>
            <Nav/>
            <About/>
            <Exprience/>
            <Services/>
            <Portfolio/>
            <Contact/>
        </>
    )
}
export default App